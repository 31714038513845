import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { userActions, userFeature } from 'rio-core';
import { of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { categoriesActions } from './categories.actions';
import { CategoriesService } from './categories.service';
import { Category, PromptCategory } from './models';

@Injectable()
export class CategoriesEffects {
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoriesActions.loadCategories),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([, organizationId]) =>
        this.categoriesService.getCategories(organizationId).pipe(
          map(categories =>
            categoriesActions.loadCategoriesSuccess({ categories }),
          ),
          catchError(() => of(categoriesActions.loadCategoriesFailure())),
        ),
      ),
    ),
  );

  createCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoriesActions.createCategory),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([{ name }, organizationId]) =>
        this.categoriesService.createCategory(organizationId, name).pipe(
          map((category: PromptCategory) =>
            categoriesActions.createCategorySuccess({ category }),
          ),
          catchError(() => of(categoriesActions.createCategoryFailure())),
        ),
      ),
    ),
  );

  editCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoriesActions.editCategory),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([{ category }, organizationId]) =>
        this.categoriesService.editCategory(organizationId, category).pipe(
          map((category: Category) =>
            categoriesActions.editCategorySuccess({ category }),
          ),
          catchError(() => of(categoriesActions.editCategoryFailure())),
        ),
      ),
    ),
  );

  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoriesActions.deleteCategory),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([{ categoryId }, organizationId]) =>
        this.categoriesService.deleteCategory(organizationId, categoryId).pipe(
          map(() => categoriesActions.deleteCategorySuccess({ categoryId })),
          catchError(() => of(categoriesActions.deleteCategoryFailure())),
        ),
      ),
    ),
  );

  selectOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selectOrganization),
      map(() => this.router.url),
      filter(url => url === '/' || url.includes('/admin-panel')),
      map(() => categoriesActions.loadCategories()),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private categoriesService: CategoriesService,
    private router: Router,
  ) {}
}
